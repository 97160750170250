export const actionTypes = {
  // global
  setCurrentPhase: 'Set_Current_Phase',
  setPrice: 'Set_Price',
  setCurrentTokenId: 'Set_Current_TokenId',
  setTokenIds: 'Set_Token_Ids',
  setClaimable: 'Set_Claimable',
  setCoins: 'Set_Coins',
  setUsedTokenIdsOf: 'Set_UsedTokenIdsOf',
};
